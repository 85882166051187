import 'webpack-entry';
import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';

import AppConfig from 'util/AppConfig';

import Routes from './o365/common/Routes';
import O365InputConfiguration from './o365/O365InputConfiguration';
import o365App from './o365/O365App';
import EmbeddedO365App from './o365/EmbeddedO365App';
import ScriptNotificationDetails from './script-notifications/ScriptNotificationDetails';
import ScriptNotificationForm from './script-notifications/ScriptNotificationForm';
import ScriptNotificationSummary from './script-notifications/ScriptNotificationSummary';
import LDAPAdapterFieldSet from './components/adapters/ldap/LDAPAdapterFieldSet';
import LDAPAdapterSummary from './components/adapters/ldap/LDAPAdapterSummary';
import LDAPAdapterDocumentation from './components/adapters/ldap/LDAPAdapterDocumentation';
import URLhausAdapterFieldSet from './components/adapters/urlhaus/URLhausAdapterFieldSet';
import URLhausAdapterSummary from './components/adapters/urlhaus/URLhausAdapterSummary';
import URLhausAdapterDocumentation from './components/adapters/urlhaus/URLhausAdapterDocumentation';

import packageJson from '../../package.json';
import {GreyNoiseMultiAdapterDocumentation} from "./components/adapters/greynoise";
import {GreyNoiseMultiAdapterFieldSet} from "./components/adapters/greynoise";
import {GreyNoiseMultiAdapterSummary} from "./components/adapters/greynoise";

const manifest = new PluginManifest(packageJson, {
  routes: [
    { path: Routes.INTEGRATIONS.O365.ACTIVITYAPI.index, component: o365App },
  ],
  inputConfiguration: [
    {
      type: 'org.graylog.enterprise.integrations.office365.Office365Input',
      component: O365InputConfiguration,
      embeddedComponent: EmbeddedO365App,
    },
  ],
  lookupTableAdapters: [
    {
      type: 'LDAP',
      displayName: 'Active Directory User Lookup',
      formComponent: LDAPAdapterFieldSet,
      summaryComponent: LDAPAdapterSummary,
      documentationComponent: LDAPAdapterDocumentation,
    },
    {
      type: 'GreyNoise Lookup [Enterprise]',
      displayName: 'GreyNoise Lookup [Enterprise]',
      formComponent:GreyNoiseMultiAdapterFieldSet,
      summaryComponent: GreyNoiseMultiAdapterSummary,
      documentationComponent: GreyNoiseMultiAdapterDocumentation,
    },
        {
     type: 'urlhaus',
      displayName: 'URLhaus Malware Distribution URL Lookup [Enterprise]',
      formComponent: URLhausAdapterFieldSet,
      summaryComponent: URLhausAdapterSummary,
      documentationComponent: URLhausAdapterDocumentation,
    },
  ],
  eventNotificationTypes: AppConfig.isCloud() ? [] : [
    {
      type: 'script-notification-v1',
      displayName: 'Script Notification [Enterprise]',
      formComponent: ScriptNotificationForm,
      summaryComponent: ScriptNotificationSummary,
      detailsComponent: ScriptNotificationDetails,
      defaultConfig: ScriptNotificationForm.defaultConfig,
    },
  ],
});

PluginStore.register(manifest);
